<template>
  <div class="dropdown-data">
    <div>
      <div v-if="loading" class="mt-1">
        <div
          class="
            w-100
            d-flex
            justify-content-between
            pt-1
            px-1
            mb-1
            rounded-sm
            border
          "
          v-for="n in 8"
          :key="n"
        >
          <b-skeleton
            v-for="n in 4"
            :key="n"
            animation="wave"
            height="25px"
            width="20%"
          ></b-skeleton>
        </div>
      </div>
      <div v-else>
        <b-list-group disabled >
          <b-list-group-item variant="primary" disabled  class="mt-50">
            <slot name="header" ></slot>
          </b-list-group-item>
        </b-list-group>

        <b-list-group v-for="(item, i) in options" :key="i">
          <b-list-group-item class="mt-50">
            <template v-for="(index, name) in $scopedSlots" v-slot:[name]>
              <slot :name="name" v-bind="item"></slot>
            </template>
            <slot name="detals" v-bind="item"></slot>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>

    <div
      v-if="!loading && options.length == 0"
      class="mb-0 w-100 my-2 bg-light-primary rounded-sm shadow-sm"
    >
      <div class="alert-body p-1">
        <feather-icon icon="InfoIcon" size="16" class="mx-50" />
        <span>عذراً لا يوجد نتائج لعرضها</span>
      </div>
      <div class="w-100 rounded-sm alert-dropdown"></div>
    </div>

    <Pagination
      v-model="currentPage"
      align="center"
      first-number
      last-number
      :total-rows="total_row"
      :per-page="per_page"
      class="mb-0 mt-1"
    >
    </Pagination>
  </div>
</template>
<script>
import {
  BCard,
  BSkeleton,
  BAlert,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import Pagination from "@/components/pagination/index.vue";

import store from "./store";
import { debounce } from "lodash";

export default {
  props: {
    ep: { type: String, default: () => null },
    fields: { type: Array, default: () => [] },
    pagination: { type: Boolean, default: () => true },
    dataPath: {
      type: String,
      default: () => "data",
    },
    id: {
      type: String,
      default: () => "drobdown",
    },
    collapseType: {
      type: String,
      default: () => "border",
    },
    per_page: {
      type: Number,
      default: () => 10,
    },
    filter: {
      type: String,
      default: () => "",
    },
  },
  components: {
    BCard,
    BSkeleton,
    BListGroup,
    BListGroupItem,
    Pagination,
    BAlert,
  },
  data() {
    return {
      list: [],
      currentPage: 1,
    };
  },
  computed: {
    options() {
      return this.$store.getters[`${this.id}/options`] || this.$attrs.options;
    },
    loading() {
      return this.$store.getters[`${this.id}/loading`];
    },
    total_row() {
      return this.$store.getters[`${this.id}/total_row`];
    },
  },
  methods: {
    refreshOptions(val) {
      if (this.$store.hasModule(this.id)) {
        this.$store.dispatch(`${this.id}/fetchOptions`, {
          ep: "" || this.ep,
          path: this.dataPath,
          utils: {
            page: this.currentPage,
            filter: this.filter,
            ...val,
          },
        });
      }
    },
  },
  watch: {
    currentPage(val) {
      this.refreshOptions();
    },
    filter: debounce(function (value) {
      this.refreshOptions();
    }, 700),
  },
  created() {
    if (this.ep && !this.$attrs.options && !this.$store.hasModule(this.id)) {
      this.$store.registerModule(this.id, store);
      if (this.ep.length > 1) {
        this.$store.dispatch(`${this.id}/fetchOptions`, {
          ep: this.ep,
          path: this.dataPath,
          utils: {
            page: this.currentPage,
            filter: this.filter,
          },
        });
      }
    }
  },
  beforeDestroy() {
    if (this.$store.hasModule(this.id)) {
      this.$store.unregisterModule(this.id);
    }
  },
};
</script>

<style lang="scss" >
.dropdown-data {
  .card .card-header {
    padding-top: 0.6rem;
    padding-bottom: 00.6rem;
  }
  .alert-dropdown {
    height: 5px;
    background: #d0a15350;
  }
}
</style>